<template>
    <div class="dashboard">
    <b-container class="shlajsna">
      <b-row>
        <b-col cols="12">
          <h1 class="text-center">{{ $t('audio.deleted') }}</h1>
        </b-col>
        <b-col cols="12">
          <NavBar></NavBar>
        </b-col>
        <b-col cols="12" class="px-4">
          <b-overlay :show="showOverlay">
            <div v-if="media.length > 0">
              <MediaGridCMS :media="media" />
            </div>
            <div v-else>
              <h3>{{ $t('audio.noDeleted') }}</h3>
            </div>
          </b-overlay>          
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import NavBar from "@/components/common/NavBar.vue";
import MediaGridCMS from '@/components/admin/_utils_/MediaGridCMS.vue'

export default {
    name: "DeletedAudio",
    components: { NavBar, MediaGridCMS },
    data() {
        return {
          admin: this.$root.user,
          media: [],
          showOverlay: true
        }
    },
    async created() {
        await this.$http.get('/cms/api/media/audio/deleted/', { headers: {Authorization: this.getAuthData().accessToken }})
        .then(response => {
          this.media = response.data.content;
        }).catch(error => {
          this.handleError(error);
        });
      this.showOverlay = false;    
    }
}
</script>

<style></style>
